import { DirectiveBinding, ObjectDirective } from 'vue';

interface ResizeColumnOptions {
  minWidth?: number; // Минимальная ширина колонки
}

const resizeColumns: ObjectDirective<HTMLElement, ResizeColumnOptions | undefined> = {
  mounted(el: HTMLElement, binding: DirectiveBinding<ResizeColumnOptions | undefined>) {
    const columns = el.querySelectorAll('th');
    let isResizing = false;
    let startX: number;
    let startWidth: number;
    let currentColumn: HTMLElement;

    const onMouseMove = (e: MouseEvent) => {
      if (!isResizing || !currentColumn) return;

      const width = startWidth + (e.pageX - startX);
      const minWidth = binding.value?.minWidth || 50;
      currentColumn.style.width = `${Math.max(width, minWidth)}px`;
    };

    const onMouseUp = () => {
      isResizing = false;
      document.removeEventListener('mousemove', onMouseMove);
      document.removeEventListener('mouseup', onMouseUp);
    };

    columns.forEach((column: HTMLElement) => {
      const resizeHandle = document.createElement('div');
      resizeHandle.style.width = '5px';
      resizeHandle.style.height = '100%';
      resizeHandle.style.position = 'absolute';
      resizeHandle.style.right = '0';
      resizeHandle.style.top = '0';
      resizeHandle.style.cursor = 'col-resize';
      resizeHandle.style.userSelect = 'none';

      resizeHandle.addEventListener('mousedown', (e: MouseEvent) => {
        isResizing = true;
        startX = e.pageX;
        startWidth = parseInt(window.getComputedStyle(column).width, 10);
        currentColumn = column;

        document.addEventListener('mousemove', onMouseMove);
        document.addEventListener('mouseup', onMouseUp);
      });
      // eslint-disable-next-line no-param-reassign
      column.style.position = 'relative';
      column.appendChild(resizeHandle);
    });
  },
};

export default resizeColumns;
