import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_btn = _resolveComponent("q-btn")!

  return (_openBlock(), _createBlock(_component_q_btn, {
    flat: "",
    rounded: "",
    class: "q-px-xs pinned-button-wrapper"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_icon, {
        name: "push_pin",
        color: $props.pinned ? 'accent' : 'primary',
        class: _normalizeClass({ 'pinned': $props.pinned })
      }, null, 8, ["color", "class"])
    ]),
    _: 1
  }))
}