
import { computed, defineComponent, PropType } from 'vue';
import { DEFAULT_COLUMN_WIDTH } from '@/constants';

export default defineComponent({
  name: 'ExpandableCell',
  props: {
    text: {
      type: String as PropType<string>,
      default: '',
    },
    width: {
      type: Number as PropType<number>,
    },
  },
  setup(props) {
    const styleObject = computed(() => ({
      '--width': props.width ? `${props.width}px` : 'auto',
    }));

    return {
      styleObject,
    };
  },
});
